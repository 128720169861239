import React, { lazy, Suspense } from 'react';
import FallbackPage from '../../layout/FallbackPage/FallbackPage';

const LazyDashboard = lazy(() => import('./Dashboard'));

const Dashboard = props => (
  <Suspense fallback={<FallbackPage/>}>
    <LazyDashboard {...props} />
  </Suspense>
);

export default Dashboard;
