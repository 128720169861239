import React, { lazy, Suspense } from 'react';
import FallbackPage from '../../../layout/FallbackPage/FallbackPage';

const LazyLoginPage = lazy(() => import('./LoginPage'));

const LoginPage = props => (
  <Suspense fallback={<FallbackPage/>}>
    <LazyLoginPage {...props} />
  </Suspense>
);

export default LoginPage;
