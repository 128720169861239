import React, { lazy, Suspense } from 'react';
import FallbackPage from '../../../layout/FallbackPage/FallbackPage';

const LazyNewAccount = lazy(() => import('./NewAccount'));

const NewAccount = props => (
  <Suspense fallback={<FallbackPage/>}>
    <LazyNewAccount {...props} />
  </Suspense>
);

export default NewAccount;
