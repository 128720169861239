import React from 'react'

const Backbtn = () => {
  return (
<svg style={{cursor:"pointer"}} width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fillRule="evenodd" clipRule="evenodd" d="M1.45968 13.1928H24V10.9674H1.45968V13.1928Z" fill="#AE8D78" />
  <path fillRule="evenodd" clipRule="evenodd" d="M0 12L12.2029 24L13.639 22.2532L3.21242 12L13.639 1.74678L12.2029 0L0 12Z" fill="#AE8D78" />
</svg>
  )
}

export default Backbtn