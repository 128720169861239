import React, { lazy, Suspense } from 'react';
import FallbackPage from '../../../layout/FallbackPage/FallbackPage';

const LazyNewPassword = lazy(() => import('./NewPassword'));

const NewPassword = props => (
  <Suspense fallback={<FallbackPage/>}>
    <LazyNewPassword {...props} />
  </Suspense>
);

export default NewPassword;
