import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './Navbar.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import Logo from '../../assets/svg/Logos/Darklogo';
import ThemeSwitch from '../ThemeSwitch/ThemeSwitch';
import Backbtn from '../../assets/svg/Backbtn';
import Logoutbtn from '../../assets/svg/Logoutbtn';
import Setingsbtn from '../../assets/svg/Setingsbtn';
import Darklogo from '../../assets/svg/Logos/Darklogo';
import LightLogo from '../../assets/svg/Logos/LightLogo';
import HeaderText from '../../assets/svg/HeaderText';
import { useNavigate } from 'react-router-dom';
import { logout } from '../../pages/Login/actions/LoginActions';

const Navbar = () => {

  const { userInfo, userToken ,theme} = useSelector((state) => state.login)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  // automatically authenticate user if token is found
  useEffect(() => {
    if (userToken) {
      // dispatch(getUserDetails())
    }
  }, [userToken, dispatch])

  const handleLogout = ()=>{
    dispatch(logout())
    navigate("/auth/logout")
  }


  return (
    <header className={styles.Navbar__head}>
      <div className={styles.Navbar__head__left}>
        {userToken && 
        <div onClick={()=>navigate(-1)}>
          <Backbtn/>
        </div>
        }
      </div>
      <div className={styles.Navbar__head__center}>
        {theme=="light"?<LightLogo />:<Darklogo/>}
        <HeaderText color={theme=="light"?"#4F5A6C":"white"}/>
        <h1 className={styles.Navbar__head__center__span}>
          Peekerton.
          <ThemeSwitch />
        </h1>
      </div>
      <div className={styles.Navbar__head__right}>
        {userToken&& <>
        <div onClick={()=>handleLogout()}>
        <Logoutbtn/>
        </div>
        <div>
        <Setingsbtn/>
        </div>
        </>}
      </div>
    </header>
  )
};

Navbar.propTypes = {};

Navbar.defaultProps = {};

export default Navbar;
