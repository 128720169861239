import React from 'react'

const HeaderText = ({color}) => {
  return (
<svg width={90} height={19} style={{margin:"11px 0px 18px 0px"}} viewBox="0 0 90 19" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M1.42772 2.29894H0.888019V3.19271H0.413086V0.446003H1.49248C2.11853 0.446003 2.5287 0.816591 2.5287 1.36157C2.50711 1.97195 2.01059 2.29894 1.42772 2.29894ZM1.42772 0.881989H0.86643V1.86296H1.42772C1.79471 1.86296 2.01059 1.66676 2.01059 1.36157C2.03218 1.03458 1.79471 0.881989 1.42772 0.881989Z" fill={color}/>
  <path d="M6.95445 0.860189H5.42171V1.57957H6.78175V2.01555H5.42171V2.75673H6.97604V3.19271H4.94678V0.446003H6.95445V0.860189Z" fill={color}/>
  <path d="M11.5091 0.860189H9.9764V1.57957H11.3364V2.01555H9.9764V2.75673H11.5307V3.19271H9.50146V0.446003H11.5091V0.860189Z" fill={color}/>
  <path d="M16.453 3.19271H15.8701L14.9419 1.95015L14.5101 2.38614V3.19271H14.0352V0.446003H14.5101V1.81936L15.8054 0.446003H16.3882L15.2657 1.62316L16.453 3.19271Z" fill={color}/>
  <path d="M20.8138 0.860189H19.2811V1.57957H20.6411V2.01555H19.2811V2.75673H20.8354V3.19271H18.8062V0.446003H20.8138V0.860189Z" fill={color}/>
  <path d="M25.088 3.19271L24.4187 2.23355H23.8143V3.19271H23.3394V0.446003H24.5483C25.1743 0.446003 25.5629 0.772993 25.5629 1.31798C25.5629 1.77576 25.3039 2.03735 24.9153 2.14635L25.6493 3.19271H25.088ZM24.5051 0.881989H23.8143V1.79756H24.5051C24.8505 1.79756 25.0664 1.62316 25.0664 1.33977C25.088 1.03458 24.8721 0.881989 24.5051 0.881989Z" fill={color}/>
  <path d="M29.233 3.19271H28.758V0.881992H27.8945V0.424206H30.0965V0.881992H29.233V3.19271Z" fill={color}/>
  <path d="M33.7017 3.23632C32.8597 3.23632 32.2769 2.60414 32.2769 1.81936C32.2769 1.03459 32.8597 0.402409 33.7017 0.402409C34.5436 0.402409 35.1265 1.03459 35.1265 1.81936C35.1265 2.60414 34.522 3.23632 33.7017 3.23632ZM33.7017 0.838394C33.162 0.838394 32.795 1.27438 32.795 1.81936C32.795 2.36434 33.1835 2.80033 33.7232 2.80033C34.2629 2.80033 34.6299 2.36434 34.6299 1.81936C34.6299 1.27438 34.2414 0.838394 33.7017 0.838394Z" fill={color}/>
  <path d="M39.5306 0.446003H40.0055V3.19271H39.5954L38.1058 1.23078V3.19271H37.6309V0.446003H38.0842L39.5522 2.34254V0.446003H39.5306Z" fill={color}/>
  <path d="M48.3598 3.19271H47.8633L47.5827 2.53874H46.2874L46.0068 3.19271H45.5103L46.6976 0.424206H47.1509L48.3598 3.19271ZM46.935 0.990989L46.4601 2.08095H47.3884L46.935 0.990989Z" fill={color}/>
  <path d="M53.1737 3.19271H52.5908L51.6626 1.95015L51.2308 2.38614V3.19271H50.7559V0.446003H51.2308V1.81936L52.5261 0.446003H53.1089L51.9864 1.62316L53.1737 3.19271Z" fill={color}/>
  <path d="M56.6495 3.19271H56.1745V0.881992H55.311V0.424206H57.513V0.881992H56.6495V3.19271Z" fill={color}/>
  <path d="M60.4705 0.446003V3.19271H59.9956V0.446003H60.4705Z" fill={color}/>
  <path d="M65.1553 0.860189H63.6009V1.57957H64.961V2.01555H63.6009V2.75673H65.1553V3.19271H63.126V0.446003H65.1337V0.860189H65.1553Z" fill={color}/>
  <path d="M69.7968 1.14358C69.7968 1.47057 69.6025 1.64497 69.4082 1.75396C69.732 1.86296 69.9479 2.05915 69.9479 2.42974C69.9479 2.93112 69.5377 3.19271 68.9333 3.19271H67.6812V0.446003H68.9117C69.4514 0.446003 69.7968 0.707594 69.7968 1.14358ZM69.3218 1.20898C69.3218 0.990986 69.1491 0.860189 68.8469 0.860189H68.1561V1.57957H68.8037C69.106 1.60137 69.3218 1.47057 69.3218 1.20898ZM69.473 2.36434C69.473 2.12455 69.3002 1.99375 68.9117 1.99375H68.1561V2.75673H68.9548C69.2571 2.75673 69.473 2.62594 69.473 2.36434Z" fill={color}/>
  <path d="M73.7256 3.23632C72.8837 3.23632 72.3008 2.60414 72.3008 1.81936C72.3008 1.03459 72.8837 0.402409 73.7256 0.402409C74.5675 0.402409 75.1504 1.03459 75.1504 1.81936C75.1504 2.60414 74.5675 3.23632 73.7256 3.23632ZM73.7256 0.838394C73.1859 0.838394 72.8189 1.27438 72.8189 1.81936C72.8189 2.36434 73.2075 2.80033 73.7472 2.80033C74.2869 2.80033 74.6539 2.36434 74.6539 1.81936C74.6539 1.27438 74.2653 0.838394 73.7256 0.838394Z" fill={color}/>
  <path d="M77.6763 0.446003H78.1512V2.75673H79.576V3.19271H77.6763V0.446003Z" fill={color}/>
  <path d="M84.6276 3.19271H84.1094L83.8288 2.53874H82.5335L82.2529 3.19271H81.7563L82.9437 0.424206H83.397L84.6276 3.19271ZM83.2027 0.990989L82.7278 2.08095H83.6561L83.2027 0.990989Z" fill={color}/>
  <path d="M88.1464 3.23632C87.3045 3.23632 86.7432 2.62594 86.7432 1.81936C86.7432 1.05639 87.326 0.402409 88.1464 0.402409C88.6213 0.402409 88.902 0.533204 89.1826 0.772996L88.8804 1.14358C88.6645 0.969189 88.4702 0.838394 88.1248 0.838394C87.6283 0.838394 87.2397 1.27438 87.2397 1.81936C87.2397 2.38614 87.6067 2.80033 88.168 2.80033C88.427 2.80033 88.6645 2.71313 88.8156 2.58234V2.05915H88.1248V1.64497H89.2905V2.80033C89.0315 3.04012 88.6429 3.23632 88.1464 3.23632Z" fill={color}/>
  <path d="M11.9193 8.70794H10.3866V9.42731H11.7466V9.8633H10.3866V10.6045H11.9409V11.0405H9.91162V8.29375H11.9193V8.70794Z" fill={color}/>
  <path d="M15.503 9.42732C16.0858 9.55811 16.3881 9.7761 16.3881 10.2339C16.3881 10.7571 15.9779 11.0623 15.4166 11.0623C15.0064 11.0623 14.6179 10.9097 14.2725 10.6263L14.5531 10.2775C14.8122 10.4955 15.0712 10.6263 15.4166 10.6263C15.7188 10.6263 15.8916 10.4955 15.8916 10.2775C15.8916 10.0813 15.7836 9.9723 15.2871 9.8633C14.7042 9.73251 14.3804 9.55811 14.3804 9.03493C14.3804 8.55534 14.769 8.22836 15.3087 8.22836C15.6973 8.22836 16.0211 8.35915 16.3017 8.57715L16.0427 8.94773C15.8052 8.77334 15.5461 8.66434 15.3087 8.66434C15.028 8.66434 14.8553 8.81694 14.8553 8.99133C14.8553 9.20932 14.9849 9.31832 15.503 9.42732Z" fill={color}/>
  <path d="M19.9717 11.0405H19.4968V8.72974H18.6333V8.27195H20.8353V8.72974H19.9717V11.0405Z" fill={color}/>
  <path d="M25.5415 11.0405H25.045L24.7643 10.3865H23.4691L23.1884 11.0405H22.6919L23.8792 8.27195H24.3326L25.5415 11.0405ZM24.1167 8.83873L23.6418 9.9287H24.57L24.1167 8.83873Z" fill={color}/>
  <path d="M30.0531 8.99133C30.0531 9.31832 29.8588 9.49271 29.6645 9.60171C29.9884 9.71071 30.2042 9.9069 30.2042 10.2775C30.2042 10.7789 29.7941 11.0405 29.1896 11.0405H27.9375V8.29375H29.168C29.7077 8.29375 30.0531 8.55534 30.0531 8.99133ZM29.5782 9.05673C29.5782 8.83873 29.4055 8.70794 29.1033 8.70794H28.4124V9.42731H29.0601C29.3623 9.44911 29.5782 9.31832 29.5782 9.05673ZM29.7293 10.2121C29.7293 9.9723 29.5566 9.8415 29.168 9.8415H28.4124V10.6045H29.2112C29.535 10.6045 29.7293 10.4737 29.7293 10.2121Z" fill={color}/>
  <path d="M32.687 8.29375H33.1619V10.6045H34.5868V11.0405H32.687V8.29375Z" fill={color}/>
  <path d="M37.5443 8.29375V11.0405H37.0693V8.29375H37.5443Z" fill={color}/>
  <path d="M41.2574 9.42732C41.8402 9.55811 42.1425 9.7761 42.1425 10.2339C42.1425 10.7571 41.7323 11.0623 41.171 11.0623C40.7608 11.0623 40.3723 10.9097 40.0269 10.6263L40.3075 10.2775C40.5666 10.4955 40.8256 10.6263 41.171 10.6263C41.4733 10.6263 41.646 10.4955 41.646 10.2775C41.646 10.0813 41.538 9.9723 41.0415 9.8633C40.4586 9.73251 40.1348 9.55811 40.1348 9.03493C40.1348 8.55534 40.5234 8.22836 41.0631 8.22836C41.4517 8.22836 41.7755 8.35915 42.0561 8.57715L41.7971 8.94773C41.5596 8.77334 41.3005 8.66434 41.0631 8.66434C40.7824 8.66434 40.6097 8.81694 40.6097 8.99133C40.6097 9.20932 40.7393 9.31832 41.2574 9.42732Z" fill={color}/>
  <path d="M45.1214 9.8633V11.0187H44.6465V8.27195H45.1214V9.40551H46.4167V8.27195H46.8916V11.0187H46.4167V9.8633H45.1214Z" fill={color}/>
  <path d="M51.555 8.70794H50.0223V9.42731H51.3823V9.8633H50.0223V10.6045H51.5766V11.0405H49.5474V8.29375H51.555V8.70794Z" fill={color}/>
  <path d="M55.0952 11.0405H54.0806V8.29375H55.0952C55.9587 8.29375 56.5416 8.88233 56.5416 9.66711C56.5416 10.4519 55.9587 11.0405 55.0952 11.0405ZM55.0952 8.72974H54.5555V10.6045H55.0952C55.6565 10.6045 56.0451 10.2121 56.0451 9.66711C56.0451 9.12213 55.6781 8.72974 55.0952 8.72974Z" fill={color}/>
  <path d="M63.5147 10.3647L62.6512 9.07852V11.0405H62.1763V8.29375H62.6944L63.5147 9.60171L64.3351 8.29375H64.8532V11.0405H64.3782V9.07852L63.5147 10.3647Z" fill={color}/>
  <path d="M68.8253 10.3647L67.9618 9.07852V11.0405H67.4868V8.29375H68.0049L68.8253 9.60171L69.6456 8.29375H70.1637V11.0405H69.6888V9.07852L68.8253 10.3647Z" fill={color}/>
  <path d="M74.589 11.0405L73.8766 9.9941L73.1642 11.0405H72.6245L73.6176 9.64531L72.6677 8.29375H73.229L73.8982 9.27472L74.589 8.29375H75.1287L74.1788 9.64531L75.1719 11.0405H74.589Z" fill={color}/>
  <path d="M79.36 11.0405L78.6476 9.9941L77.9352 11.0405H77.3955L78.3885 9.64531L77.4387 8.29375H78L78.6692 9.27472L79.36 8.29375H79.8997L78.9498 9.64531L79.9429 11.0405H79.36Z" fill={color}/>
  <path d="M25.5201 17.2751C26.1029 17.4059 26.4052 17.6239 26.4052 18.0816C26.4052 18.6048 25.995 18.91 25.4337 18.91C25.0235 18.91 24.635 18.7574 24.2896 18.474L24.5702 18.1252C24.8293 18.3432 25.0883 18.474 25.4337 18.474C25.7359 18.474 25.9086 18.3432 25.9086 18.1252C25.9086 17.929 25.8007 17.82 25.3042 17.7111C24.7213 17.5803 24.3975 17.4059 24.3975 16.8827C24.3975 16.4031 24.7861 16.0761 25.3258 16.0761C25.7144 16.0761 26.0382 16.2069 26.3188 16.4249L26.0598 16.7955C25.8223 16.6211 25.5632 16.5121 25.3258 16.5121C25.0451 16.5121 24.8724 16.6647 24.8724 16.8391C24.8724 17.0571 25.002 17.1661 25.5201 17.2751Z" fill={color}/>
  <path d="M29.9884 18.8882H29.5134V16.5775H28.6499V16.1197H30.8519V16.5775H29.9884V18.8882Z" fill={color}/>
  <path d="M34.457 18.9318C33.6151 18.9318 33.0322 18.2996 33.0322 17.5149C33.0322 16.7301 33.6151 16.0979 34.457 16.0979C35.299 16.0979 35.8818 16.7301 35.8818 17.5149C35.8818 18.2996 35.299 18.9318 34.457 18.9318ZM34.457 16.5339C33.9173 16.5339 33.5503 16.9699 33.5503 17.5149C33.5503 18.0598 33.9389 18.4958 34.4786 18.4958C35.0183 18.4958 35.3853 18.0598 35.3853 17.5149C35.3853 16.9699 34.9967 16.5339 34.457 16.5339Z" fill={color}/>
  <path d="M39.6599 18.9318C38.8612 18.9318 38.2783 18.2996 38.2783 17.5149C38.2783 16.7301 38.8612 16.0979 39.6815 16.0979C40.1781 16.0979 40.4803 16.2723 40.7393 16.5339L40.4371 16.8827C40.2212 16.6865 39.9838 16.5339 39.6815 16.5339C39.1634 16.5339 38.7748 16.9699 38.7748 17.5149C38.7748 18.0598 39.1418 18.4958 39.6815 18.4958C40.0053 18.4958 40.2212 18.365 40.4587 18.147L40.7609 18.4522C40.4803 18.7356 40.1781 18.9318 39.6599 18.9318Z" fill={color}/>
  <path d="M45.618 18.8882H45.0352L44.1069 17.6457L43.6751 18.0816V18.8882H43.2002V16.1415H43.6751V17.5149L44.9704 16.1415H45.5533L44.4307 17.3187L45.618 18.8882Z" fill={color}/>
  <path d="M48.4461 17.711V18.8664H47.9712V16.1197H48.4461V17.2533H49.7414V16.1197H50.2163V18.8664H49.7414V17.711H48.4461Z" fill={color}/>
  <path d="M54.167 18.9318C53.3251 18.9318 52.7422 18.2996 52.7422 17.5149C52.7422 16.7301 53.3251 16.0979 54.167 16.0979C55.0089 16.0979 55.5918 16.7301 55.5918 17.5149C55.5918 18.2996 55.0089 18.9318 54.167 18.9318ZM54.167 16.5339C53.6273 16.5339 53.2603 16.9699 53.2603 17.5149C53.2603 18.0598 53.6489 18.4958 54.1886 18.4958C54.7283 18.4958 55.0953 18.0598 55.0953 17.5149C55.0953 16.9699 54.7067 16.5339 54.167 16.5339Z" fill={color}/>
  <path d="M58.1177 16.1415H58.5926V18.4522H60.0174V18.8882H58.1177V16.1415Z" fill={color}/>
  <path d="M63.795 18.2124L62.9315 16.9263V18.8882H62.4565V16.1415H62.9747L63.795 17.4495L64.6153 16.1415H65.1334V18.8882H64.6585V16.9263L63.795 18.2124Z" fill={color}/>
</svg>
  )
}

export default HeaderText