import React, { Fragment, useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import { useDispatch } from 'react-redux';
import moon from "../../assets/images/moon.png";
import sun from "../../assets/images/sun.png";
import styles from "../ThemeSwitch/ThemeSwitch.module.scss";
import {storeTheme} from "../../pages/Login/actions/LoginActions"

const themeType = {
  dark: 'dark',
  light: 'light'
};

const DarkModeSwitch = () => {
  const dispatch = useDispatch()
  const [mode, setMode] = useState(() => {
    if (typeof window !== 'undefined') {
      const val = localStorage.getItem('theme');
      return val ? JSON.parse(val) : themeType.light;
    }
    return themeType.light;
  });

  useEffect(() => {
    if (typeof window !== 'undefined') {
      localStorage.setItem('theme', JSON.stringify(mode));
      dispatch(storeTheme(mode))
    }
  }, [mode]);

  const toggleMode = () => {
    setMode(mode === themeType.light ? themeType.dark : themeType.light);
  };

  return (
    <Fragment>
      <Helmet>
        <body className={mode} />
      </Helmet>
      <div className={styles.ThemeSwitch} onClick={toggleMode} role="button" tabIndex="0" onKeyDown={toggleMode}>
        {mode === themeType.light ? <span><img src={sun}/></span> : <span><img src={moon}/></span>}
      </div>
    </Fragment>
  );
};

export default DarkModeSwitch;