import React, { lazy, Suspense } from 'react';
import FallbackPage from '../../../layout/FallbackPage/FallbackPage';

const LazyEnterPassword = lazy(() => import('./EnterPassword'));

const EnterPassword = props => (
  <Suspense fallback={<FallbackPage/>}>
    <LazyEnterPassword {...props} />
  </Suspense>
);

export default EnterPassword;
