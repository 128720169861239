import { createSlice } from "@reduxjs/toolkit";
import { getUserDetails, registerUser, userLogin } from "./actions";

// initialize userToken from local storage
const userToken = localStorage.getItem('userToken')
    ? localStorage.getItem('userToken')
    : null

const initialState = {
    signUpDetails: {},
    loading: false,
    userInfo: {}, // for user object
    userToken: null, // for storing the JWT
    error: null,
    success: false, // for monitoring the registration process.,
    theme:"light"
}

let loginSlice = createSlice({
    name: "login",
    initialState,
    reducers: {
        storeSignUpDetails: function (state, action) {
            state.signUpDetails = action.payload
        },
        storeReset: function (state, action) {
            state.error = null
        },
        storeTheme:function(state,action){
            state.theme = action.payload
        },
        logout:function(state,action){
            state.userInfo={}
            state.userToken=null

        }
    },
    extraReducers: {
        [userLogin.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [userLogin.fulfilled]: (state, { payload }) => {
            state.loading = false
            state.userInfo = payload
            state.userToken = payload.access_token
        },
        [userLogin.rejected]: (state, { payload }) => {
            state.loading = false
            // state.error = payload
            state.success=false
        },
        // register user
        [registerUser.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [registerUser.fulfilled]: (state, { payload }) => {
            state.loading = false
            state.success = true // registration successful
        },
        [registerUser.rejected]: (state, { payload }) => {
            state.loading = false
            state.error = payload
        },
        [getUserDetails.pending]: (state) => {
            state.loading = true
        },
        [getUserDetails.fulfilled]: (state, { payload }) => {
            state.loading = false
            state.userInfo = payload
        },
        [getUserDetails.rejected]: (state, { payload }) => {
            state.loading = false
        },
    },
})

export const { storeSignUpDetails ,storeReset,storeTheme,logout} = loginSlice.actions;
export default loginSlice.reducer;