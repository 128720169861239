import React, { lazy, Suspense } from 'react';
import FallbackPage from '../../../layout/FallbackPage/FallbackPage';

const LazyLoggedOut = lazy(() => import('./LoggedOut'));

const LoggedOut = props => (
  <Suspense fallback={<FallbackPage/>}>
    <LazyLoggedOut {...props} />
  </Suspense>
);

export default LoggedOut;
