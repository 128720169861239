import { Spin } from 'antd';
import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'
import { Suspense } from 'react';
import { Provider } from "react-redux";
import { BrowserRouter as Router, Navigate, Outlet, Route, Routes } from 'react-router-dom';
import Dashboard from './pages/Dashboard/Dashboard.lazy';
import EmailSent from './pages/Login/EmailSent/EmailSent.lazy';
import EnterEmail from './pages/Login/EnterEmail/EnterEmail.lazy';
import EnterPassword from './pages/Login/EnterPassword/EnterPassword.lazy';
import LoggedOut from './pages/Login/LoggedOut/LoggedOut.lazy';
import LoginError from './pages/Login/LoginError/LoginError.lazy';
import LoginPage from './pages/Login/LoginPage/LoginPage.lazy';
import NewAccount from './pages/Login/NewAccount/NewAccount.lazy';
import NewPassword from './pages/Login/NewPassword/NewPassword.lazy';
import Welcome from './pages/Login/Welcome/Welcome.lazy';
import ProtectedRoute from './protectedRoutes/ProtectedRoute';
import { persistor, store } from './redux/store';
import { PersistGate } from 'redux-persist/integration/react';
import FallbackPage from './layout/FallbackPage/FallbackPage';
import Layout from './layout/Layout/Layout';

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={<FallbackPage />} persistor={persistor}>
        <Router>
          <Suspense fallback={<FallbackPage />}>
              <Layout>
            <Routes>
              <Route path="/" element={<Navigate replace to="/welcome" />} />
              <Route
                path="/"
                element={
                  <Outlet />
                }>
                <Route path="welcome" element={<Welcome />} />
              </Route>
              <Route
                path="/auth"
                element={
                  <Outlet />
                }>
                <Route path="login" element={<LoginPage />} />
                <Route path="password" element={<EnterPassword />} />
                <Route path="error" element={<LoginError />} />
                <Route path="emailconfirm" element={<EmailSent />} />
                <Route
                  path="signup"
                  element={
                    <Outlet />
                  }>
                  <Route path="" element={<NewAccount />} />
                  <Route path="newpassword" element={<NewPassword />} />
                </Route>
                <Route path="logout" element={<LoggedOut />} />
              </Route>
              <Route
                path="/dashboard"
                element={<ProtectedRoute />
                }>
                <Route path="" element={<Dashboard />} />
              </Route>
            </Routes>
              </Layout>
          </Suspense>
        </Router>
      </PersistGate>

    </Provider>

  );
}

export default App;
