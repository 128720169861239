import React, { lazy, Suspense } from 'react';
import FallbackPage from '../../../layout/FallbackPage/FallbackPage';

const LazyEmailSent = lazy(() => import('./EmailSent'));

const EmailSent = props => (
  <Suspense fallback={<FallbackPage/>}>
    <LazyEmailSent {...props} />
  </Suspense>
);

export default EmailSent;
