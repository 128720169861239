import React from 'react'

const Logoutbtn = () => {
  return (
<svg width={24} style={{cursor:'pointer',marginRight:"22px"}} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M18 18L16.35 16.26L19.41 13.2H9.6V10.8H19.41L16.35 7.74L18 6L24 12L18 18ZM0 24V0H13.2V2.4H2.4V21.6H13.2V24H0Z" fill="#AE8D78" />
</svg>
  )
}

export default Logoutbtn