import React, { lazy, Suspense } from 'react';
import FallbackPage from '../../../layout/FallbackPage/FallbackPage';

const LazyLoginError = lazy(() => import('./LoginError'));

const LoginError = props => (
  <Suspense fallback={<FallbackPage/>}>
    <LazyLoginError {...props} />
  </Suspense>
);

export default LoginError;
