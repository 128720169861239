import React, { lazy, Suspense } from 'react';
import FallbackPage from '../../../layout/FallbackPage/FallbackPage';

const LazyWelcome = lazy(() => import('./Welcome'));

const Welcome = props => (
  <Suspense fallback={<FallbackPage/>}>
    <LazyWelcome {...props} />
  </Suspense>
);

export default Welcome;
