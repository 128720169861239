import React from 'react'

const Darklogo = () => {
  return (
    <svg width={25} height={33} viewBox="0 0 25 33" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M13.0536 6.06011L15.968 9.6352C15.9896 9.6788 16.0544 9.657 16.076 9.6352C16.1407 9.548 16.2271 9.5044 16.3134 9.4826C16.335 9.4826 16.3566 9.4608 16.3566 9.439L18.1052 5.97291C18.2779 5.64593 18.2347 5.25354 18.0405 4.94835L16.1191 2.09264C16.076 2.02724 15.9896 1.98364 15.9033 1.98364H15.8817C15.7953 1.98364 15.7305 2.02724 15.6658 2.09264L13.0321 5.51513C12.9241 5.66772 12.9241 5.88572 13.0536 6.06011Z" fill="white" />
  <path d="M24.0421 14.6273C24.0205 14.6055 23.9989 14.6055 23.9773 14.6055C23.9557 14.6055 23.9341 14.6055 23.9125 14.6273L22.4661 15.8262C22.4446 15.848 22.4446 15.8698 22.4446 15.8916L22.3798 16.284L22.682 16.8072C22.7468 16.9162 22.7684 17.0688 22.7252 17.1996L21.9912 19.8373L22.4446 19.4449L22.9195 18.4203C23.2865 17.592 23.5887 16.7418 23.8262 15.8698L24.1068 14.7363C24.1068 14.6709 24.0852 14.6273 24.0421 14.6273Z" fill="white" />
  <path d="M16.6803 9.54805C16.7018 9.56985 16.7234 9.59164 16.745 9.61344L19.7889 13.4937C19.8321 13.5591 19.8753 13.6463 19.8753 13.7117L19.9616 14.4529L19.9832 14.4311L20.0264 13.9733C20.0264 13.9297 20.048 13.8861 20.0696 13.8425C20.3286 13.2321 20.6524 12.5999 20.8251 11.946C20.8467 11.9024 20.8467 11.8588 20.8683 11.7934C20.9331 11.5318 20.9547 11.2702 20.9763 11.0086C21.0626 9.78784 20.7388 8.58888 20.415 7.41171C20.307 7.06293 20.1991 6.73594 20.0911 6.38715C20.0048 6.08196 19.94 5.77677 19.7889 5.49338C19.7673 5.44978 19.7242 5.42798 19.681 5.42798C19.6378 5.42798 19.5946 5.44978 19.573 5.49338L16.6803 9.39545C16.6587 9.48265 16.6587 9.52625 16.6803 9.54805Z" fill="white" />
  <path d="M20.3071 14.0171V14.0607C20.3935 13.9735 20.523 13.9299 20.6309 13.9299C20.6525 13.9299 20.6525 13.9299 20.6741 13.9299C20.8252 13.9517 20.9764 14.0389 21.0627 14.1697L21.991 15.761L22.6818 12.9925C22.8113 12.4693 22.8545 11.9461 22.8113 11.4011L22.725 10.4202C22.725 10.3112 22.617 10.2676 22.5954 10.2676H22.5739C22.5307 10.2676 22.4659 10.2894 22.4227 10.3766L20.3071 13.9735C20.3071 13.9953 20.3071 13.9953 20.3071 14.0171Z" fill="white" />
  <path d="M19.5949 14.9325C19.6813 14.8235 19.7245 14.6927 19.7029 14.5619L19.6381 13.8861C19.6165 13.7553 19.5734 13.6463 19.487 13.5591L16.8317 11.0522C16.7022 10.9432 16.5079 10.9432 16.3999 11.0522L13.0538 14.2785C13.0322 14.3003 13.0322 14.3439 13.0322 14.3657L13.0538 14.5619C13.0538 14.6273 13.0754 14.6709 13.1186 14.7145L16.5295 18.0498C16.5942 18.0934 16.6158 18.1806 16.6158 18.246L16.5942 19.2487C16.5942 19.3141 16.659 19.3359 16.7022 19.3141C16.7238 19.3141 16.7238 19.2923 16.7238 19.2705L17.0908 18.137C17.1123 18.0716 17.1339 18.028 17.1771 17.9844L19.5949 14.9325Z" fill="white" />
  <path d="M20.8468 20.7965L21.5376 20.1861L22.3148 17.3522C22.3796 17.1124 22.3364 16.8727 22.1853 16.6765L21.0843 15.2595C20.9332 15.0633 20.6525 15.0415 20.4583 15.2159L17.3496 18.1588C17.328 18.1806 17.328 18.2024 17.3064 18.2242L17.0474 19.0308L17.0905 19.009C17.1985 18.9218 17.3496 18.8782 17.4791 18.8782C17.6302 18.8782 17.7598 18.9218 17.8893 19.009L19.897 20.4477C20.156 20.6439 20.264 20.9927 20.1344 21.2979L19.5947 22.5841L19.8322 22.4315L19.9833 22.2789L20.8252 20.8401C20.8037 20.8183 20.8252 20.8183 20.8468 20.7965Z" fill="white" />
  <path d="M23.8043 19.0309C23.7612 19.0091 23.718 18.9873 23.6748 18.9873C23.61 18.9873 23.5669 19.0091 23.5237 19.0527L21.1706 20.8838C21.0627 20.971 20.9979 21.0582 20.9331 21.1672L20.415 22.061L20.6741 21.9084C20.7604 21.843 20.89 21.8212 20.9979 21.8212C21.1922 21.8212 21.3865 21.9302 21.4944 22.0828L21.9046 22.6932C22.0341 22.8894 22.0125 23.151 21.8614 23.3036L20.8684 24.3499L20.9979 24.3281L21.9478 23.4998C22.4011 23.1074 22.7249 22.6278 22.9192 22.061L23.9123 19.2707C23.9555 19.1617 23.8907 19.0745 23.8043 19.0309Z" fill="white" />
  <path d="M16.1841 25.1345L17.0044 24.1971C17.026 24.1753 17.0476 24.1535 17.0692 24.1535L18.861 23.0418C19.0553 22.9328 19.2064 22.7584 19.2927 22.5404L19.8756 21.1888C19.8756 21.167 19.8972 21.1452 19.8972 21.1234C19.9404 20.9272 19.8108 20.7092 19.6166 20.6438L18.0406 20.0989C17.76 20.0117 17.4578 20.0117 17.1771 20.1425L13.2265 22.0608C13.1402 22.1044 13.0754 22.1698 13.0322 22.257C13.0322 22.2788 13.0106 22.3006 13.0106 22.3224L12.7516 23.2598C12.73 23.347 12.7732 23.4341 12.8595 23.4559L15.925 24.3279C16.0545 24.3715 16.1841 24.4587 16.2273 24.5895C16.292 24.7203 16.292 24.8729 16.2273 25.0037L16.1841 25.1345Z" fill="white" />
  <path d="M16.1839 25.8974C16.2271 25.8974 16.2702 25.8974 16.2918 25.9192L16.4861 25.9846L19.5948 24.5895C19.6164 24.5895 19.638 24.5677 19.6595 24.5677L20.264 24.4587C20.3504 24.4369 20.4367 24.3933 20.4799 24.3279L21.624 23.0853C21.7104 22.9981 21.4945 22.8237 21.4513 22.7801C21.4298 22.7583 21.365 22.693 21.3002 22.6494C21.2139 22.584 21.1059 22.584 21.0196 22.6276L17.328 24.3279C17.2417 24.3715 17.1769 24.4151 17.1122 24.5023L15.8385 25.9628L15.8169 26.0064L16.0544 25.9192C16.1191 25.9192 16.1407 25.8974 16.1839 25.8974Z" fill="white" />
  <path d="M21.8613 24.9602H21.8397L19.8968 25.0474C19.7889 25.0474 19.6593 25.0692 19.5514 25.1128L17.1335 26.0284C17.0688 26.0502 17.0688 26.1592 17.1335 26.181L18.4288 26.617C18.5583 26.6605 18.7095 26.6606 18.839 26.5952L21.9045 25.1128C21.9692 25.0692 21.9476 25.0256 21.9476 25.0038C21.9476 25.0038 21.926 24.9602 21.8613 24.9602Z" fill="white" />
  <path d="M9.18932 26.2027L11.6072 27.0747C11.6288 27.0747 11.6503 27.0965 11.6503 27.0965L12.1253 27.3581C12.2116 27.4017 12.298 27.4017 12.3843 27.3581L12.8593 27.0965C12.8809 27.0965 12.9024 27.0747 12.9024 27.0747L15.3203 26.2027C15.4066 26.1809 15.4714 26.1155 15.5146 26.0283L15.9679 25.2436C15.9895 25.1782 15.9895 25.1346 15.9679 25.0692C15.9463 25.0038 15.9032 24.982 15.8384 24.9602L12.6002 24.3934C12.4923 24.3716 12.3627 24.3716 12.2548 24.3716C12.2332 24.3716 12.2332 24.3716 12.2116 24.3716C12.1037 24.3716 11.9958 24.3716 11.8878 24.3934L8.64963 24.9602C8.58486 24.982 8.54169 25.0256 8.5201 25.0692C8.49851 25.1346 8.49851 25.1782 8.5201 25.2436L8.97344 26.0283C9.03821 26.1155 9.10297 26.1591 9.18932 26.2027Z" fill="white" />
  <path d="M18.3643 27.0311L16.2702 26.7695H16.2487H16.2271L13.14 27.3799C13.0968 27.4017 13.0105 27.4235 12.9457 27.4453L12.4276 27.7287C12.3844 27.7505 12.3197 27.7723 12.2765 27.7723C12.2333 27.7723 12.1685 27.7505 12.1254 27.7287L11.6072 27.4453C11.5641 27.4235 11.4777 27.4017 11.413 27.3799L8.32589 26.7695H8.3043H8.28271L6.18869 27.0311C6.14551 27.0529 6.14551 27.0747 6.14551 27.0965C6.14551 27.1183 6.14551 27.1619 6.18869 27.1619L9.05988 28.0993C9.31893 28.1865 9.57799 28.2301 9.83704 28.2519C10.3552 28.2955 10.8733 28.3391 11.3914 28.3827C11.9311 28.4263 12.4708 28.4481 13.0105 28.4045C13.4422 28.3609 13.8524 28.3391 14.2842 28.2955C14.4353 28.2737 14.5864 28.2737 14.7375 28.2519C14.9966 28.2301 15.2556 28.1865 15.5147 28.0993L18.3859 27.1619C18.429 27.1401 18.429 27.0965 18.429 27.0965C18.4074 27.0965 18.4074 27.0529 18.3643 27.0311Z" fill="white" />
  <path d="M11.823 5.40623L11.8446 5.44982C11.8877 5.49342 11.9525 5.51522 11.9957 5.47162C12.082 5.42803 12.1684 5.40623 12.2547 5.40623C12.3411 5.40623 12.449 5.42803 12.5138 5.47162C12.557 5.49342 12.6217 5.49342 12.6649 5.44982L12.6865 5.40623L14.3487 2.46332C14.4351 2.31073 14.4135 2.11453 14.284 1.98374L12.449 0.0653979C12.4274 0.0217993 12.3627 0 12.2979 0H12.2763H12.2547C12.19 0 12.1036 0.0217993 12.0604 0.0871972L10.2255 2.00554C10.0959 2.13633 10.0743 2.33253 10.1607 2.48512L11.823 5.40623Z" fill="white" />
  <path d="M8.17468 9.43907C8.17468 9.46087 8.19627 9.46087 8.21786 9.48267C8.30421 9.50447 8.39057 9.56987 8.45533 9.63527C8.47692 9.67887 8.54168 9.67887 8.56327 9.63527L11.4776 6.06018C11.6072 5.90759 11.6072 5.66779 11.4776 5.5152L8.84391 2.09271C8.80073 2.02731 8.71438 1.98371 8.62803 1.98371V1.96191C8.54168 1.96191 8.47692 2.00551 8.41215 2.07091L6.49083 4.92662C6.29654 5.23181 6.25336 5.6242 6.42606 5.95119L8.17468 9.43907Z" fill="white" />
  <path d="M12.255 6.80127C12.1471 6.80127 12.0175 6.84487 11.9312 6.93206L8.93047 9.70058C8.77935 9.83137 8.75777 10.0712 8.8873 10.2238L11.7369 14.104L11.7585 14.1258C11.8233 14.2348 11.9528 14.2784 12.0607 14.2566C12.1255 14.2348 12.1902 14.2348 12.255 14.2348C12.3198 14.2348 12.3845 14.2348 12.4493 14.2566C12.5788 14.3002 12.6868 14.2348 12.7515 14.1258L12.7731 14.104L15.6227 10.2238C15.7307 10.0712 15.7307 9.83137 15.5796 9.70058L12.5788 6.93206C12.4925 6.84487 12.3845 6.80127 12.255 6.80127Z" fill="white" />
  <path d="M1.61206 18.3985L2.087 19.4231L2.54034 19.8155L1.80635 17.1342C1.76317 17.0034 1.78476 16.8726 1.84953 16.7418L2.15176 16.2186L2.087 15.8262C2.087 15.8044 2.06541 15.7826 2.06541 15.7608L0.619014 14.5618C0.597426 14.54 0.554253 14.54 0.554253 14.54C0.532665 14.54 0.511073 14.54 0.489486 14.5618C0.467898 14.5836 0.424726 14.6054 0.446314 14.649L0.726954 15.7826C0.942833 16.72 1.24506 17.5919 1.61206 18.3985Z" fill="white" />
  <path d="M3.66311 11.8152C3.6847 11.8588 3.6847 11.9024 3.70629 11.9678C3.87899 12.6218 4.20281 13.254 4.46187 13.8643C4.48345 13.9079 4.48345 13.9515 4.50504 13.9951L4.54822 14.4529L4.56981 14.4747L4.65616 13.7335C4.65616 13.6463 4.69933 13.5809 4.74251 13.5156L7.7864 9.63528C7.80799 9.61348 7.82958 9.59168 7.85116 9.56988C7.87275 9.54808 7.87275 9.50448 7.85116 9.48268L4.95839 5.55881C4.9368 5.51521 4.89362 5.49341 4.85045 5.49341C4.80727 5.49341 4.76409 5.51521 4.74251 5.55881C4.59139 5.8204 4.52663 6.14739 4.44028 6.45258C4.33234 6.80137 4.2244 7.12836 4.11646 7.47714C3.79264 8.63251 3.46882 9.83147 3.55517 11.074C3.57676 11.292 3.59835 11.5536 3.66311 11.8152Z" fill="white" />
  <path d="M2.54014 15.739L3.46842 14.1477C3.55477 14.0169 3.6843 13.9297 3.857 13.9079C3.87859 13.9079 3.87859 13.9079 3.90018 13.9079C4.02971 13.9079 4.13765 13.9515 4.224 14.0387V13.9951C4.224 13.9733 4.224 13.9733 4.224 13.9515L2.10838 10.3546C2.06521 10.2674 2.00045 10.2456 1.95727 10.2456H1.93568C1.91409 10.2456 1.80615 10.2674 1.80615 10.3982L1.7198 11.3792C1.67663 11.9024 1.71981 12.4473 1.84933 12.9705L2.54014 15.739Z" fill="white" />
  <path d="M7.72142 11.0522L5.06611 13.5591C4.97976 13.6463 4.91499 13.7553 4.91499 13.8861L4.85023 14.5619C4.82864 14.6927 4.87182 14.8235 4.95817 14.9325L7.41919 17.9844C7.46236 18.028 7.48395 18.0934 7.50554 18.137L7.87254 19.2705C7.87254 19.2923 7.89413 19.2923 7.89413 19.3141C7.9373 19.3359 8.00207 19.3141 8.00207 19.2487L7.98048 18.246C7.98048 18.1588 8.00206 18.0934 8.06683 18.0498L11.4777 14.7145C11.5209 14.6709 11.5425 14.6273 11.5425 14.5619L11.5641 14.3657C11.5641 14.3221 11.5641 14.3003 11.5425 14.2785L8.19636 11.0522C8.04524 10.9432 7.85095 10.9432 7.72142 11.0522Z" fill="white" />
  <path d="M3.44716 15.2813L2.34617 16.6983C2.19506 16.8944 2.15188 17.1342 2.21664 17.374L2.99381 20.2079L3.68463 20.8183C3.70621 20.8401 3.7278 20.8619 3.7278 20.8837L4.56973 22.3225L4.72084 22.4751L4.95831 22.6277L4.41861 21.3415C4.28909 21.0363 4.39703 20.6875 4.65608 20.4913L6.66376 19.0526C6.7717 18.9654 6.92281 18.9218 7.07393 18.9218C7.22504 18.9218 7.35457 18.9654 7.46251 19.0526L7.50568 19.0744L7.24663 18.2678C7.24663 18.246 7.22504 18.2242 7.20345 18.2024L4.09479 15.2595C3.9005 15.0633 3.59827 15.0851 3.44716 15.2813Z" fill="white" />
  <path d="M12.0389 23.129C12.0605 23.238 12.1684 23.3034 12.2764 23.3034C12.3843 23.3034 12.4707 23.238 12.5138 23.129L12.7729 22.2352C12.8161 22.0826 12.924 21.9301 13.0535 21.8429L16.0974 19.7283C16.227 19.6411 16.2917 19.5103 16.2917 19.3577L16.3133 18.5294C16.3133 18.3768 16.2485 18.2242 16.119 18.137L12.6002 15.7391C12.5138 15.6737 12.4059 15.6519 12.298 15.6519C12.19 15.6519 12.1037 15.6955 11.9957 15.7391L8.47689 18.137C8.34737 18.2242 8.26102 18.3768 8.2826 18.5294L8.30419 19.3577C8.30419 19.5103 8.39054 19.6411 8.49848 19.7283L11.5424 21.8429C11.6719 21.9301 11.7798 22.0826 11.823 22.2352L12.0389 23.129Z" fill="white" />
  <path d="M3.53335 24.3281L3.66288 24.3499L2.66983 23.3036C2.51872 23.1292 2.49713 22.8676 2.62666 22.6932L3.03683 22.0828C3.14477 21.9084 3.33906 21.8212 3.53335 21.8212C3.64129 21.8212 3.74923 21.843 3.85717 21.9084L4.11623 22.061L3.59812 21.1672C3.53335 21.0582 3.447 20.971 3.36065 20.8838L1.00757 19.0527C0.964391 19.0091 0.899625 18.9873 0.856449 18.9873C0.813273 18.9873 0.770096 19.0091 0.72692 19.0309C0.662156 19.0745 0.597393 19.1617 0.618981 19.2925L1.61203 22.0828C1.80632 22.6278 2.15172 23.1292 2.58348 23.5216L3.53335 24.3281Z" fill="white" />
  <path d="M8.60657 24.3279L11.6721 23.4559C11.7584 23.4341 11.8016 23.3469 11.78 23.2597L11.5209 22.3224C11.5209 22.3006 11.4994 22.2788 11.4994 22.257C11.4562 22.1698 11.3914 22.1044 11.3051 22.0608L7.33289 20.1424C7.07383 20.0116 6.75002 19.9898 6.46937 20.0988L4.89346 20.6438C4.69917 20.7092 4.56964 20.9272 4.61281 21.1234C4.61281 21.1452 4.6344 21.167 4.6344 21.1888L5.21728 22.5404C5.30363 22.7366 5.45474 22.9109 5.64903 23.0417L7.44083 24.1535C7.46242 24.1753 7.484 24.1971 7.50559 24.1971L8.32593 25.1345L8.26117 24.9819C8.1964 24.8511 8.21799 24.6985 8.26117 24.5677C8.34752 24.4587 8.45546 24.3715 8.60657 24.3279Z" fill="white" />
  <path d="M3.20934 22.6714C3.14457 22.715 3.07982 22.7804 3.05823 22.8022C2.99346 22.8676 2.79917 23.0202 2.88552 23.1074L4.02968 24.3499C4.09445 24.4153 4.15921 24.4589 4.24556 24.4807L4.85002 24.5897C4.87161 24.5897 4.8932 24.5897 4.91478 24.6115L8.02345 26.0067L8.21774 25.9413C8.26092 25.9195 8.28251 25.9195 8.32568 25.9195C8.36886 25.9195 8.41203 25.9195 8.45521 25.9413L8.69267 26.0285L8.67109 25.9849L7.3974 24.5243C7.33263 24.4589 7.26787 24.3935 7.18152 24.3499L3.48999 22.6496C3.38205 22.5842 3.2741 22.606 3.20934 22.6714Z" fill="white" />
  <path d="M7.39763 26.0501L4.97979 25.1345C4.87185 25.0909 4.76391 25.0691 4.63438 25.0691L2.69147 24.9819H2.66988C2.60512 24.9819 2.58353 25.0255 2.58353 25.0473C2.58353 25.0691 2.56195 25.1345 2.62671 25.1563L5.69219 26.6387C5.82172 26.7041 5.97283 26.7041 6.10236 26.6605L7.39763 26.2245C7.4624 26.1809 7.4624 26.0719 7.39763 26.0501Z" fill="white" />
  <path d="M12.6866 29.0366C12.665 28.9494 12.5787 28.9058 12.5139 28.9058C12.2549 28.9058 11.9742 28.9058 11.7152 28.884C11.6072 28.884 11.5209 28.9494 11.5209 29.0584C11.4129 29.8868 11.4345 30.846 11.4561 31.4346C11.4777 31.7179 11.672 31.9577 11.9742 32.0231C12.9025 32.1975 13.5933 32.2847 14.1762 32.3501C14.4352 32.3719 14.5863 32.0885 14.4352 31.8705C13.4854 30.6062 12.8809 29.6688 12.6866 29.0366Z" fill="white" />
</svg>

  )
}

export default Darklogo